import React, { useState } from "react";
import mastercardIcon from "../../../assets/img/bandeiras/master-card.webp";
import visaIcon from "../../../assets/img/bandeiras/visa.webp";
import eloIcon from "../../../assets/img/bandeiras/elo-1.webp";
import amexIcon from "../../../assets/img/bandeiras/american_express.webp";
import hiperIcon from "../../../assets/img/bandeiras/hiper.webp";

const plans = [
  {
    title: "Ultra Link",
    description: "Independentemente das vendas, taxa ultra baixa",
    rates: {
      mastercard: ["1,20%", "3,70%", "5,30%", "6,05%", "6,75%", "7,46%", "8,15%", "8,75%", "9,58%", "10,38%", "11,19%", "11,95%", "12,77%", "13,67%", "14,50%", "15,31%", "16,12%", "16,95%", "17,78%"],
      visa: ["1,20%", "3,70%", "5,30%", "6,05%", "6,75%", "7,46%", "8,15%", "8,75%", "9,58%", "10,38%", "11,19%", "11,95%", "12,77%", "13,67%", "14,50%", "15,31%", "16,12%", "16,95%", "17,78%"],
      elo: ["1,80%", "4,10%", "5,38%", "6,05%", "6,79%", "7,48%", "8,19%", "9,40%", "10,12%", "10,82%", "12,15%", "12,28%", "12,98%", "14,06%", "14,91%", "15,72%", "16,58%", "17,36%", "18,19%"],
      amex: ["1,80%", "4,32%", "5,96%", "6,05%", "6,79%", "7,48%", "8,19%", "8,40%", "9,40%", "10,12%", "10,82%", "12,15%", "12,28%", "12,98%", "14,06%", "14,91%", "15,72%", "16,53%", "17,36%"],
      hiper: ["1,80%", "4,32%", "5,96%", "6,05%", "6,79%", "7,48%", "8,19%", "8,40%", "9,40%", "10,12%", "10,82%", "12,15%", "12,28%", "12,98%", "14,06%", "14,91%", "15,72%", "16,53%", "17,36%"],
      pix: ["0,60%"]
    }
  },
  {
    title: "Master Link",
    description: "Pra quem vende muito parcelado e com máquinas mais baratas",
    rates: {
      mastercard: ["1,29%", "3,90%", "5,50%", "6,25%", "6,95%", "7,66%", "8,35%", "8,95%", "9,78%", "10,58%", "11,39%", "12,15%", "12,97%", "13,87%", "14,70%", "15,51%", "16,32%", "17,15%", "17,98%"],
      visa: ["1,29%", "3,90%", "5,50%", "6,25%", "6,95%", "7,66%", "8,35%", "8,95%", "9,78%", "10,58%", "11,39%", "12,15%", "12,97%", "13,87%", "14,70%", "15,51%", "16,32%", "17,15%", "17,98%"],
      elo: ["1,95%", "4,30%", "5,58%", "6,25%", "6,99%", "7,68%", "8,39%", "9,60%", "10,32%", "11,02%", "12,35%", "12,48%", "13,18%", "14,26%", "15,11%", "15,92%", "16,78%", "17,56%", "18,39%"],
      amex: ["1,95%", "4,52%", "6,16%", "6,25%", "6,99%", "7,68%", "8,39%", "8,60%", "9,60%", "10,32%", "11,02%", "12,35%", "12,48%", "13,18%", "14,26%", "15,11%", "15,92%", "16,73%", "17,56%"],
      hiper: ["1,95%", "4,52%", "6,16%", "6,25%", "6,99%", "7,68%", "8,39%", "8,60%", "9,60%", "10,32%", "11,02%", "12,35%", "12,48%", "13,18%", "14,26%", "15,11%", "15,92%", "16,73%", "17,56%"],
      pix: ["0,65%"]
    }
  },
 /* {
    title: "Super Link",
    description: "Pra quem vende muito à vista e com máquinas mais baratas.",
    rates: {
      mastercard: ["1,25%", "3,36%", "7,78%", "8,42%", "9,08%", "9,71%", "10,37%", "12,30%", "12,93%", "13,59%", "14,22%", "15,82%", "15,54%", "16,20%", "16,82%", "17,48%", "18,13%", "18,60%", "19,45%"],
      visa: ["1,25%", "3,36%", "7,78%", "8,42%", "9,08%", "9,71%", "10,37%", "12,30%", "12,93%", "13,59%", "14,22%", "15,82%", "15,54%", "16,20%", "16,82%", "17,48%", "18,13%", "18,60%", "19,45%"],
      elo: ["1,70%", "4,02%", "8,71%", "9,40%", "10,05%", "10,70%", "11,35%", "13,22%", "13,93%", "14,51%", "15,17%", "15,82%", "16,47%", "17,09%", "17,75%", "18,38%", "19,02%", "19,68%", "20,31%"],
      amex: ["2,80%", "4,02%", "8,75%", "9,40%", "10,05%", "10,70%", "11,35%", "13,25%", "13,90%", "14,51%", "15,18%", "15,82%", "16,47%", "17,09%", "17,75%", "18,38%", "19,02%", "19,68%", "20,31%"],
      hiper: ["1,70%", "4,02%", "8,71%", "9,40%", "10,05%", "10,70%", "11,35%", "13,22%", "13,93%", "14,51%", "15,17%", "15,82%", "16,47%", "17,09%", "17,75%", "18,38%", "19,02%", "19,68%", "20,31%"],
      pix: ["0,65%"]
    }
  } */
];

export default function PlansWithToggle() {
  const [activePlan, setActivePlan] = useState(0);
  const [activeGroup, setActiveGroup] = useState("visa-mastercard");

  const renderRates = (label, icon, rates) => (
    <div className="unique-rate-container">
      <div className="unique-rate-header">
        <img src={icon} alt={label} className="unique-rate-icon" />
        <span className="unique-rate-title">{label}</span>
      </div>
      <table className="unique-rate-table">
        <tbody>
          <tr>
            <td className="unique-rate-cell">Débito</td>
            <td className="unique-rate-cell">{rates[0]}</td>
          </tr>
          {rates.slice(1).map((rate, index) => (
            <tr key={index}>
              <td className="unique-rate-cell">Crédito {index + 1}x</td>
              <td className="unique-rate-cell">{rate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <section className="unique-section-container">
      <h3 className="unique-section-title">Tabela de Taxas</h3>
      <div className="cs-height_30 cs-height_lg_30" ></div>
      <div className="unique-plan-buttons-container">
        {plans.map((plan, index) => (
          <button
            key={index}
            className={`unique-plan-button ${
              activePlan === index ? "active" : ""
            }`}
            onClick={() => setActivePlan(index)}
          >
            {plan.title}
          </button>
        ))}
      </div>
      <div className="cs-height_20 cs-height_lg_20" ></div>

      <div className="unique-toggle-buttons">
        <button
          className={`unique-toggle-button ${
            activeGroup === "visa-mastercard" ? "active" : ""
          }`}
          onClick={() => setActiveGroup("visa-mastercard")}
        >
          <img src={mastercardIcon} alt="Mastercard" />
          <img src={visaIcon} alt="Visa" />
          Visa & Mastercard
        </button>
        <button
          className={`unique-toggle-button ${
            activeGroup === "elo" ? "active" : ""
          }`}
          onClick={() => setActiveGroup("elo")}
        >
          <img src={eloIcon} alt="Elo" /> Elo
        </button>
        <button
          className={`unique-toggle-button ${
            activeGroup === "amex-hiper" ? "active" : ""
          }`}
          onClick={() => setActiveGroup("amex-hiper")}
        >
          <img src={amexIcon} alt="Amex" />
          <img src={hiperIcon} alt="Hiper" />
          Amex & Hiper
        </button>
      </div>
      <div className="cs-height_20 cs-height_lg_20" ></div>
      <div className="unique-rates-container">
        {activeGroup === "visa-mastercard" && (
          <>
            {renderRates("Mastercard", mastercardIcon, plans[activePlan].rates.mastercard)}
            {renderRates("Visa", visaIcon, plans[activePlan].rates.visa)}
          </>
        )}
        {activeGroup === "elo" &&
          renderRates("Elo", eloIcon, plans[activePlan].rates.elo)}
        {activeGroup === "amex-hiper" && (
          <>
            {renderRates("American Express", amexIcon, plans[activePlan].rates.amex)}
            {renderRates("Hiper/Hipercard", hiperIcon, plans[activePlan].rates.hiper)}
          </>
        )}
      </div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
    </section>
  );
}
