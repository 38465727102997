import React from "react";

export default function Footer() {
  return (
    <footer className="cs-footer">
      <div className="cs-height_75 cs-height_lg_70"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item">
              <div className="cs-footer_widget_text">
                <img src="../../../img/cashlink/logo_cashlink-branca.png" alt="Logo" />
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
                <div className="cs-social_btns cs-style1">
                  <a href="https://apps.apple.com/us/app/cashlink/id6742584593">
                    <img src="../../../img/cashlink/Apple.png" alt="App Store" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=app.cashlink.p4dqlllw">
                    <img src="../../../img/cashlink/PlayStore.png" alt="PlayStore" />
                  </a>
                </div>
              <div/>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item widget_nav_menu">
              <h2 className="cs-widget_title">Maquininhas</h2>
              <ul className="menu">
                <li>
                  <a href="/">Point Link</a>
                </li>
                <li>
                  <a href="/">Smart Link</a>
                </li>
                <li>
                  <a href="/planos">Planos e Taxas</a>
                </li>
                <li>
                  <a href="/Simulador">Simulador</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item widget_nav_menu">
              <h2 className="cs-widget_title">Parcerias</h2>
              <ul className="menu">
                <li>
                  <a href="https://wa.me/message/ZGCPJHTCM75TJ1">Seja um Link</a>
                </li>
                <li>
                  <a href="https://afiliados.cashlinkpay.com.br">Afiliados</a>
                </li>
                <li>
                  <a href="/beneficios">Benefícios</a>
                </li>
                <li>
                  <a href="https://zaplinkcrm.com.br" target="__blank">Chatbot AI - WhatsApp</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item widget_nav_menu">
              <h2 className="cs-widget_title">Institucional</h2>
              <ul className="menu">
                <li>
                  <a href="/sobre">Sobre Nós</a>
                </li>
                <li>
                  <a href="https://conta.cashlinkpay.com.br">Portal CashLink</a>
                </li>
                <li>
                  <a href="/">Central de Ajuda</a>
                </li>
                <li>
                  <a href="/#faq">Perguntas Frequentes</a>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
      <div className="cs-height_40 cs-height_lg_30"></div>
      <div className="cs-copyright text-center">
        <div className="container">
          <span style={{ fontWeight: "bold", color: "#4A73f4" }}>WhatsApp:</span> (61) 98221-7107 |  
          <span style={{ fontWeight: "bold", color: "#4A73f4" }}> E-Mail:</span> contato@cashlinkpay.com.br
        </div>
        <div className="container">
          CashLink Fintech © 2025. CNPJ: 59.299.539/0001-36, Todos os direitos reservados.
        </div>
      </div>
    </footer>
  );
}
